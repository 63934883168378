import React, {
  FunctionComponent,
  ReactElement,
  ReactNode,
  useLayoutEffect,
  useState,
} from 'react';
import type { PageProps } from 'gatsby';
import { MotionConfigProps } from 'framer-motion';
import { PageContext, PageContextData } from '../../context/PageContext';
import { useReducedMotion } from '../../utils/hooks';
import Login from '../../pages/login';
import Page404 from '../../pages/404';
import Frontpage from '../../templates/Frontpage';
import Generic, { GenericQuery } from '../../templates/Generic';
import BlogCollection, {
  BlogCollectionQuery,
} from '../../templates/BlogCollection';
import BlogPost, { BlogPostQuery } from '../../templates/BlogPost';
import EventCollection, {
  EventCollectionQuery,
} from '../../templates/EventCollection';
import EventPost, { EventPostQuery } from '../../templates/EventPost';
import { Auth } from '../Auth';
import { FooterWithQuery } from '../Footer/withQuery';
import { Head } from '../Head';
import { Layout } from '../Layout';
import { NavigationWithQuery } from '../Navigation/withQuery';

export type AppProps = {
  children: ReactNode;
  reducedMotion?: MotionConfigProps['reducedMotion'];
} & Omit<PageProps, 'children'>;

export const App = ({
  children,
  data,
  location,
  pageContext,
  pageResources,
  reducedMotion,
}: AppProps): ReactElement => {
  const component = pageResources?.component as unknown as FunctionComponent;
  const locale = (pageContext as PageContextData).locale;

  const title =
    component === Generic
      ? (data as GenericQuery)?.contentfulPageGeneric?.title
      : component === BlogCollection
      ? (data as BlogCollectionQuery)?.contentfulPageBlogCollection?.title
      : component === BlogPost
      ? (data as BlogPostQuery)?.contentfulPageBlogPost?.title
      : component === EventCollection
      ? (data as EventCollectionQuery)?.contentfulPageEventCollection?.title
      : component === EventPost
      ? (data as EventPostQuery)?.contentfulPageEventPost?.title
      : undefined;
  const image =
    component === BlogPost
      ? (data as BlogPostQuery)?.contentfulPageBlogPost?.image?.fixed?.src
      : component === EventPost
      ? (data as EventPostQuery)?.contentfulPageEventPost?.image?.fixed?.src
      : undefined;
  const authors =
    component === BlogPost
      ? (data as BlogPostQuery)?.contentfulPageBlogPost?.authors
          ?.map((author) => author.name)
          .concat(
            (data as BlogPostQuery)?.contentfulPageBlogPost?.authorsOther || []
          )
      : undefined;

  const hideNavigation = component === Login || component === Page404;
  const hideFooter = component === Login || component === Page404;
  const hideCover = component !== Frontpage;

  const [newPageContext, setNewPageContext] =
    useState<Partial<PageContextData>>(pageContext);
  const reduceMotion = useReducedMotion(reducedMotion);

  useLayoutEffect(() => {
    /* if motion is reduced, update page context immediately */
    if (reduceMotion) {
      setNewPageContext(pageContext);
      return;
    }
    /* otherwise, wait long enough for layout animations to run (see <Layout> component) */
    const timer = setTimeout(() => {
      setNewPageContext(pageContext);
    }, 500);
    return () => clearTimeout(timer);
  }, [reduceMotion, pageContext]);

  return (
    <PageContext value={newPageContext as PageContextData}>
      <Head pageTitle={title} pageImage={image} authors={authors} />
      <Auth location={location}>
        <Layout
          navigation={<NavigationWithQuery />}
          footer={<FooterWithQuery showOverflow={!reduceMotion} />}
          hideNavigation={hideNavigation}
          hideFooter={hideFooter}
          hideCover={hideCover}
          keyNavigation={locale}
          keyFooter={locale}
          keyMain={location.pathname}
          reducedMotion={reducedMotion}
        >
          {children}
        </Layout>
      </Auth>
    </PageContext>
  );
};
