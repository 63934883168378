import React, {
  createContext,
  ReactElement,
  ReactNode,
  useContext,
} from 'react';
import { PageProps } from 'gatsby';
import { Locale } from '../../utils/types';

export type PageContextData = {
  locale: Locale;
  id: string;
  updatedAt: string;
  currentPath: string;
  translationPath: string;
  prevPath?: string;
  nextPath?: string;
  location?: PageProps['location'];
};

export type PageContextProps = {
  value: PageContextData;
  children: ReactNode;
};

const Context = createContext<PageContextData>({
  locale: 'en',
  id: '',
  updatedAt: new Date().toISOString(),
  currentPath: '/en',
  translationPath: '/fi',
});

export const PageContext = (props: PageContextProps): ReactElement => (
  <Context.Provider {...props} />
);

export const usePageContext = (): PageContextData => useContext(Context);
